function InstructionDesc({ no, desc }: { no: string; desc: string }) {
  return (
    <div className="flex gap-1 text-base font-semibold">
      <span>{no} </span>
      <span>{desc}</span>
    </div>
  );
}

export default InstructionDesc;
