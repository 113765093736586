import { useInfiniteQuery } from 'react-query';
import { getCategoryList } from '../../api/category';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { getInfiniteQueryNextParams } from '../../utils/getInfiniteQueryNextParams';

export const useGetCategoryList = () => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEYS_ENUM.getCategoryList],
    queryFn: ({ pageParam = 1 }) => getCategoryList({ page: pageParam }),
    getNextPageParam: getInfiniteQueryNextParams
  });
};
