import { getContactUploadUrl } from '../api/contact';
import {
  getEmailDownloadUrl,
  getEmailPresignedUrl,
  getWhatsAppThumbnail,
  pushNotifPresignedUrl,
  uploadFile
} from '../api/file-upload';
import { getUploadUnsubscribePresignedUrl } from '../api/unsubscribe';
import { getPresignedURL } from '../api/whatsapp';
import { FileUploadInterface } from '../components/file-upload/file-upload.interface';
import { GetPresignedURLResponseInterface } from '../shared/automated-campaign.interface';
import { UploadEmailAttachmentResponse } from '../shared/file-upload.interface';
import { getFileExtension } from './fileUtils';
import { retry } from './retryAPI';

const getFileData = (file: File) => {
  return {
    fileName: file.name,
    contentType: file.type,
    fileExtension: getFileExtension(file.name)
  };
};

async function uploadToSignedUrl(
  file: File,
  signedUrl?: { message: string; data?: GetPresignedURLResponseInterface }
) {
  const { contentType } = getFileData(file);
  if (!signedUrl?.data?.upload_url) throw new Error();
  const { upload_url, id } = signedUrl.data;
  await uploadFile(file, upload_url, contentType);
  return { url: upload_url, id };
}

const fetchWhatsAppThumbnail = async (id: string) => {
  const response = await getWhatsAppThumbnail(id);
  if (!response?.data?.thumbnail) throw new Error();
  return response;
};

// initially created for WhatsApp templates
export const uploadToCloudStorage = async (file: File, base64: string) => {
  const { fileName, contentType, fileExtension } = getFileData(file);
  const resPresignedUrl = await getPresignedURL({
    name: fileName,
    extension: fileExtension
  });

  if (!resPresignedUrl) throw new Error();

  const url = resPresignedUrl.upload_url;
  await uploadFile(file, url, contentType);

  const resThumbnail = await retry(fetchWhatsAppThumbnail, [resPresignedUrl.id], 10);

  const responseData: FileUploadInterface = {
    id: resPresignedUrl.id,
    url: resThumbnail.data.thumbnail,
    name: fileName
  };

  return responseData;
};

// created for Push Notification image file
export const uploadPushNotificationImage = async (file: File, base64: string) => {
  const { fileName, fileExtension } = getFileData(file);
  const resPresignedUrl = await pushNotifPresignedUrl({
    name: fileName,
    extension: fileExtension
  });

  const { id } = await uploadToSignedUrl(file, resPresignedUrl);
  const responseData: FileUploadInterface = {
    id,
    url: base64,
    name: fileName
  };

  return responseData;
};

export const emailUploadHandler = async (
  file: File,
  fn: (params: { name: string; extension: string }) => Promise<UploadEmailAttachmentResponse>
) => {
  const { fileName: name, fileExtension: extension } = getFileData(file);
  const resPresignedUrl = await fn({ name, extension });
  return await uploadToSignedUrl(file, resPresignedUrl);
};

// created for Email template images & files
export const uploadEmailFile = async (file: File): Promise<FileUploadInterface> => {
  const { id } = await emailUploadHandler(file, getEmailPresignedUrl);
  const { download_url } = await retry(getEmailDownloadUrl, [id], 10);
  return { id, url: download_url, name: file.name };
};

export const uploadContactFile = async (file: File) => {
  const { fileName: name } = getFileData(file);
  const resPresignedUrl = await getContactUploadUrl({ name });
  const response = await uploadToSignedUrl(file, resPresignedUrl);

  const responseData: FileUploadInterface = {
    ...response,
    name,
    file
  };

  return responseData;
};

export const uploadUnsubscribeFile = async (file: File) => {
  const { fileName: name } = getFileData(file);
  const resPresignedUrl = await getUploadUnsubscribePresignedUrl(name);
  const response = await uploadToSignedUrl(file, resPresignedUrl);
  const responseData: FileUploadInterface = { ...response, name };
  return responseData;
};
