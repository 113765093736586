import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useDebounce } from '../../../../hooks/useDebounce';
import { RichTextPrecodingMap } from '../../../rich-text-input/rich-text-input.interface';
import { LexicalEditor } from 'lexical';
import { parseEditorToString } from '../../../../utils/lexical';
import _ from 'lodash';

export enum InvalidPrecodingEnum {
  OPENING,
  CLOSING,
  BOTH,
  NONE
}

export const ERROR_BRACKET = '{error_bracket}';

const precodingWordHashmap = new Map<string, InvalidPrecodingEnum>();

export function validateInvalidPrecodingBracketsInWord(
  word: string,
  precodingMap: RichTextPrecodingMap
): InvalidPrecodingEnum {
  if (precodingWordHashmap.has(word)) return precodingWordHashmap.get(word)!;

  let openingBracketCount = 0;
  let closingBracketCount = 0;

  let match;
  let clonedWord = word;
  while ((match = /\{\{[a-z_]+\}\}/.exec(clonedWord))) {
    const matchedPrecoding = match[0].slice(2, -2);

    if (!precodingMap.hasOwnProperty(matchedPrecoding)) return InvalidPrecodingEnum.BOTH;

    clonedWord = clonedWord.replace(match[0], '');
  }

  for (const char of clonedWord) {
    if (char === '{') openingBracketCount++;
    if (char === '}') closingBracketCount++;
  }

  switch (true) {
    case !openingBracketCount && !closingBracketCount:
      precodingWordHashmap.set(word, InvalidPrecodingEnum.NONE);
      return InvalidPrecodingEnum.NONE;
    case openingBracketCount > closingBracketCount:
      precodingWordHashmap.set(word, InvalidPrecodingEnum.OPENING);
      return InvalidPrecodingEnum.OPENING;
    case closingBracketCount > openingBracketCount:
      precodingWordHashmap.set(word, InvalidPrecodingEnum.CLOSING);
      return InvalidPrecodingEnum.CLOSING;
    default:
      precodingWordHashmap.set(word, InvalidPrecodingEnum.BOTH);
      return InvalidPrecodingEnum.BOTH;
  }
}

export function useValidatePrecoding(text: string, precodingMap: RichTextPrecodingMap) {
  const debouncedText = useDebounce(text || '');

  const invalidBrackets: InvalidPrecodingEnum = useMemo(() => {
    let hasInvalidOpening = false;
    let hasInvalidClosing = false;

    const words = debouncedText.split(' ');

    for (const word of words) {
      const errorEnum = validateInvalidPrecodingBracketsInWord(word, precodingMap);
      hasInvalidOpening ||= errorEnum === InvalidPrecodingEnum.OPENING;
      hasInvalidClosing ||= errorEnum === InvalidPrecodingEnum.CLOSING;

      const isBoth = errorEnum === InvalidPrecodingEnum.BOTH;
      if (isBoth || (hasInvalidOpening && hasInvalidClosing)) {
        return InvalidPrecodingEnum.BOTH;
      }
    }

    if (hasInvalidOpening) return InvalidPrecodingEnum.OPENING;
    if (hasInvalidClosing) return InvalidPrecodingEnum.CLOSING;
    return InvalidPrecodingEnum.NONE;
  }, [debouncedText, precodingMap]);

  return invalidBrackets;
}

export function useMergePrecodingValidationErrors(
  precodingErrors: InvalidPrecodingEnum[]
): InvalidPrecodingEnum {
  return useMemo(() => {
    const hasBoth = precodingErrors.includes(InvalidPrecodingEnum.BOTH);
    const hasOpeningError = precodingErrors.includes(InvalidPrecodingEnum.OPENING);
    const hasClosingError = precodingErrors.includes(InvalidPrecodingEnum.CLOSING);

    if (hasBoth || (hasOpeningError && hasClosingError)) return InvalidPrecodingEnum.BOTH;
    if (hasOpeningError) return InvalidPrecodingEnum.OPENING;
    if (hasClosingError) return InvalidPrecodingEnum.CLOSING;

    return InvalidPrecodingEnum.NONE;
    // eslint-disable-next-line
  }, [JSON.stringify(precodingErrors)]);
}

export function usePrecodingValidationErrorMessage(
  errorMessageTemplate: string,
  precodingError: InvalidPrecodingEnum
) {
  const errorBracket: string = useMemo(() => {
    switch (precodingError) {
      case InvalidPrecodingEnum.OPENING:
        return '"{"';
      case InvalidPrecodingEnum.CLOSING:
        return '"}"';
      case InvalidPrecodingEnum.BOTH:
        return '"{" dan "}"';
      default:
        return '';
    }
  }, [precodingError]);

  const errorMessage = useMemo(() => {
    if (!errorBracket) return '';
    return errorMessageTemplate.replace(ERROR_BRACKET, errorBracket);
  }, [errorBracket, errorMessageTemplate]);

  return errorMessage;
}

export const useEmailContent = (editor: LexicalEditor, precodingMap: RichTextPrecodingMap) => {
  const [emailContent, setEmailContent] = useState<string>('');

  // eslint-disable-next-line
  const debouncedOnChange = useCallback(
    _.debounce(() => {
      const emailContent = parseEditorToString(editor, precodingMap);
      setEmailContent(emailContent);
    }, 1000),
    [editor, precodingMap]
  );

  useLayoutEffect(() => {
    return editor.registerUpdateListener(debouncedOnChange);
  }, [editor, debouncedOnChange]);

  return emailContent;
};
