import { NoticeType } from 'antd/lib/message/interface';
import { useMutation } from 'react-query';
import { createTestEmailChannel } from '../../api/channel';

function useTestEmailChannel(
  id: string,
  toastMessage: (type: NoticeType, message: string) => void
) {
  return useMutation({
    mutationKey: ['testEmailChannel', id],
    mutationFn: (to: string[]) => {
      return createTestEmailChannel(id, to);
    },
    onSuccess: () => toastMessage('success', 'Email berhasil dikirim!'),
    onError: () => toastMessage('error', 'Terjadi kesalahan saat mengirimkan email.')
  });
}

export default useTestEmailChannel;
