import { NoticeType } from 'antd/lib/message/interface';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { fetchListSender } from '../../api';
import { useInfiniteQuery, useQuery } from 'react-query';
import { getErrorMessage } from '../../utils/error';
import { getListSender } from '../../api/automated-campaign';
import { getInfiniteQueryNextParams } from '../../utils/getInfiniteQueryNextParams';

export const useGetListSender = (
  id: string,
  toastMessage?: (type: NoticeType, message: string) => void
) => {
  return useQuery({
    queryKey: [QUERY_KEYS_ENUM.senderList, id],
    queryFn: () => fetchListSender(id),
    enabled: !!id,
    select: ({ data }) => {
      return data;
    },
    onError: (error: any) => {
      toastMessage?.('error', `Gagal memuat data pengirim: ${getErrorMessage(error)}`);
    }
  });
};

export const useGetSenderList = () => {
  return useInfiniteQuery({
    queryKey: [QUERY_KEYS_ENUM.emailSenderList],
    queryFn: ({ pageParam = 1 }) => getListSender({ page: pageParam }),
    getNextPageParam: getInfiniteQueryNextParams
  });
};
