import { useEffect, useMemo, useState } from 'react';
import { CommentBoxComponentProps } from './comment-box-component';
import { CommentStatusEnum } from '../../../static/cheker-maker.enum';
import { useAuth } from '../../../hooks/useAuth';

const useCommentBoxComponent = (props: CommentBoxComponentProps) => {
  const {
    element,
    comment,
    updateComment,
    anchor,
    addComment,
    onOpenChange,
    cleanedAnchor,
    removeComment,
    resolveComment
  } = props;
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);
  const [deleteComment, setDeleteComment] = useState<string | null>(null);
  const [hoverOpen, setHoverOpen] = useState(false);
  const { userData } = useAuth();
  const onHover = (e: any) => {
    e.stopPropagation();
    if (element) {
      element.classList.add('checker-hover');
    }
    if (comment && !open) {
      setHoverOpen(true);
    }
  };
  const onLeave = () => {
    if (element) {
      element.classList.remove('checker-hover');
    }
    if (comment && hoverOpen) {
      setHoverOpen(false);
    }
  };

  const onCancel = () => {
    setEdit(false);
    onOpenChange(null);
    cleanedAnchor?.();
    element?.classList.remove('checker-action-hover', 'checker-hover');
  };

  const onEdit = () => {
    setEdit(!edit);
  };

  const onDelete = (anchor: string | null = null) => {
    setDeleteComment(anchor);
  };

  const onSubmit = (value: string) => {
    if (comment) {
      updateComment({ anchor: comment.anchor, value, html: element?.outerHTML, id: comment.id });
    } else {
      addComment({ anchor, value, html: element?.outerHTML });
    }
    onCancel();
  };

  const onResolved = () => {
    resolveComment?.(comment?.id ?? '');
  };

  const handleClickTrigger = () => {
    if (!open) {
      setHoverOpen(false);
      setOpen(true);
      if (!comment) {
        setEdit(true);
      }
      element?.classList.add('checker-action-hover');
    } else {
      element?.classList.remove('checker-action-hover');
    }
  };

  const isPopoverOpen = useMemo(() => open || hoverOpen, [open, hoverOpen]);

  const isResolved = useMemo(() => comment?.status === CommentStatusEnum.RESOLVED, [comment]);
  const isCanEditDelete = useMemo(
    () => comment?.createdBy === userData.id && !isResolved,
    [comment, isResolved, userData]
  );

  const onPopoverOpenChange = (open: boolean) => {
    if (!open) {
      onCancel();
    }
    setOpen(open);
    onOpenChange(open ? anchor : null);
  };

  const onConfirmDelete = () => {
    if (deleteComment) {
      removeComment(deleteComment, comment?.id ?? '');
    }
    onDelete();
  };

  useEffect(() => {
    if (element) {
      element.addEventListener('click', handleClickTrigger);

      return () => {
        element.removeEventListener('click', handleClickTrigger);
      };
    }
  }, [element]);

  return {
    isDelete: !!deleteComment,
    isPopoverOpen,
    onHover,
    onLeave,
    isEditContent: edit,
    isHoverOpen: hoverOpen,
    onSubmit,
    handleClickTrigger,
    onCancel,
    onPopoverOpenChange,
    onEdit,
    onDelete,
    onConfirmDelete,
    onResolved,
    isResolved,
    isCanEditDelete
  };
};

export default useCommentBoxComponent;
