import { useMutation, useQueryClient } from 'react-query';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { handleAxiosError } from '../../api/error-handler/error-handler';
import { useToastMessage } from '../useQueryHelper';
import { postComment } from '../../api/checker-maker';

export const usePostComment = () => {
  const { openToast } = useToastMessage();
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEYS_ENUM.postComment],
    mutationFn: postComment,
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS_ENUM.getCommentList);
      openToast({ label: 'Komen berhasil ditambahkan' });
    },
    onError: (error) => {
      const message = handleAxiosError(error);
      openToast({ label: message, isError: true });
    }
  });
};
