import { useQuery } from 'react-query';
import { getActivityList } from '../../api/checker-maker';
import { ChannelKeyEnum } from '../../shared/master-api.interface';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';

export const useGetActivityList = (channel: ChannelKeyEnum, reviewId?: string) => {
  return useQuery({
    queryKey: [QUERY_KEYS_ENUM.getActivityList],
    queryFn: () => getActivityList(channel, reviewId),
    enabled: !!reviewId,
    retry: false
  });
};
