import { useInfiniteQuery, useQuery } from 'react-query';
import {
  GetCommentListResponseInterface,
  ParamsCommentListInterface
} from '../../shared/checker-maker.interface';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { handleAxiosError } from '../../api/error-handler/error-handler';
import { useToastMessage } from '../useQueryHelper';
import { getCommentList } from '../../api/checker-maker';
import { getInfiniteQueryNextParams } from '../../utils/getInfiniteQueryNextParams';

export const useGetCommentList = (
  params: ParamsCommentListInterface,
  { onSuccess }: { onSuccess?: (data: GetCommentListResponseInterface) => void } = {}
) => {
  const { openToast } = useToastMessage();
  return useQuery({
    queryKey: [QUERY_KEYS_ENUM.getCommentList, params],
    queryFn: () => getCommentList(params),
    enabled: !!params.id,
    onSuccess,
    onError: (error) => {
      const message = handleAxiosError(error);
      openToast({ label: message, isError: true });
    },
    refetchInterval: 10_000 // 10s
  });
};

export const useGetCommentListInfinite = (params: ParamsCommentListInterface) => {
  const { openToast } = useToastMessage();
  return useInfiniteQuery({
    queryKey: [QUERY_KEYS_ENUM.getCommentList, params],
    queryFn: ({ pageParam = 1 }) =>
      getCommentList({
        ...params,
        page: pageParam
      }),
    getNextPageParam: getInfiniteQueryNextParams,
    enabled: !!params.id,
    onError: (error) => {
      const message = handleAxiosError(error);
      openToast({ label: message, isError: true });
    }
  });
};
