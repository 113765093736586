import axiosInstance from '../config/axios/axiosWithToken';
import {
  CategoryListInterface,
  CategoryListQuery,
  DeleteCategoryInterface
} from '../shared/category.interface';

export const getCategoryList: (query: CategoryListQuery) => Promise<CategoryListInterface> = async (
  query
) => {
  const { page, limit = 10, q = '' } = query;
  const response = await axiosInstance.get(`email/category?page=${page}&limit=${limit}&q=${q}`);
  return response.data;
};

export const addNewCategory: (payload: { name: string }) => Promise<CategoryListInterface> = async (
  payload
) => {
  const response = await axiosInstance.post(`email/category`, payload);
  return response.data;
};

export const deleteCategory: (id: string) => Promise<DeleteCategoryInterface> = async (id) => {
  const response = await axiosInstance.delete(`email/category/${id}`);
  return response.data;
};
