import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { useQuery } from 'react-query';
import { redirectTo404 } from '../../utils/error';
import { useNavigate } from 'react-router';
import { getPushNotificationChannel } from '../../api/automated-campaign';

interface optionsInterface {
  enabled?: boolean;
}

export const useGetPushNotificationTemplate = (
  channelId: string,
  { enabled = true }: optionsInterface = {}
) => {
  const navigate = useNavigate();
  return useQuery({
    queryKey: [QUERY_KEYS_ENUM.getPushNotificationChannel, channelId],
    queryFn: () => getPushNotificationChannel(channelId),
    enabled: !!channelId && enabled,
    onError: redirectTo404(navigate)
  });
};
