import { LexicalEditor } from 'lexical';
import { RichTextPrecodingMap } from '../components/rich-text-input/rich-text-input.interface';
import { $generateHtmlFromNodes } from '@lexical/html';
import juice from 'juice';
import { PrecodingNode } from '../components/rich-text/nodes/PrecodingNode';
import { Token } from '@squantumengine/horizon';
import { replaceAmpersandInGCSUrls } from './url';

export type precodingParserFn = (key: string, value: string) => string;
const defaultPrecodingParser = (key: string, value: string) => value;

export function parsePrecodingInString(
  string: string = '',
  precodingMap: RichTextPrecodingMap,
  precodingParser: precodingParserFn = defaultPrecodingParser
) {
  let parsedString = string.replaceAll('&quot;', "'");

  for (const [key, value] of Object.entries(precodingMap)) {
    if (!value) continue;
    const regex = new RegExp(`\\{\\{${key}\\}\\}`, 'g');
    const precoding = precodingParser(key, value);
    parsedString = parsedString.replaceAll(regex, precoding);
  }

  // removes all PreviewSpecific__precoding that were previously used
  parsedString = parsedString.replaceAll('PreviewSpecific__precoding', '');

  return parsedString;
}

export function parseEditorToHtml(
  editor: LexicalEditor,
  style: string,
  precodingMap: RichTextPrecodingMap,
  precodingParser: precodingParserFn = defaultPrecodingParser
): string {
  let htmlString = '';
  editor.getEditorState().read(() => {
    htmlString = $generateHtmlFromNodes(editor);
  });

  const htmlStringWithoutNbsp = htmlString.replaceAll('&nbsp;', ' ');
  const normalizedImageLinkHtmlString = replaceAmpersandInGCSUrls(htmlStringWithoutNbsp);
  const htmlContentString = parsePrecodingInString(
    normalizedImageLinkHtmlString,
    precodingMap,
    precodingParser
  );

  if (!style) return htmlContentString;
  return juice.inlineContent(htmlContentString, style);
}

export function parseEditorToString(
  editor: LexicalEditor,
  precodingMap: RichTextPrecodingMap = {},
  precodingParser: precodingParserFn = defaultPrecodingParser
): string {
  const inlinedHtml = parseEditorToHtml(editor, '', precodingMap, precodingParser);

  const span = document.createElement('span');
  span.innerHTML = inlinedHtml;

  return span.textContent || span.innerText;
}

interface ParseEditorToHtmlWithPrecodingProps {
  selectedPrecoding: PrecodingNode | null;
  precodingMap: RichTextPrecodingMap;
}

export function parseEditorToHtmlWithPrecoding(
  editor: LexicalEditor,
  style: string,
  precoding: ParseEditorToHtmlWithPrecodingProps
) {
  const { selectedPrecoding, precodingMap } = precoding;
  const selectedPrecodingText = selectedPrecoding?.__text;
  const pre = selectedPrecoding ? '<span class="selected-precoding">' : '';
  const post = selectedPrecoding ? '</span>' : '';

  const precodingParser = (key: string, value: string) => {
    const isSelected = selectedPrecodingText === key;
    const precoding = isSelected ? `${pre}${value}${post}` : value;
    return precoding;
  };

  const inlinedHtmlContent = parseEditorToHtml(editor, style, precodingMap, precodingParser);
  return inlinedHtmlContent;
}

export function recolorAllPrecodingBrackets(htmlString: string = '') {
  const regex = /[{}]/g;
  return htmlString.replace(regex, `<span style="color:${Token.COLORS.orange[500]}">$&</span>`);
}

export function generateEmailHtmlWrapper(content: string, width: string): string {
  return `
    <!doctype html>
    <html>
      <head>
        <style>
          @media screen and (min-device-width: 767px), screen and (min-width: 767px) {
            .email-container {
              overflow: auto !important;
            }
          }
        </style>
      </head>
      <body>
        <div class="email-container" style="width: ${width}; max-width: 1024px; margin: 0 auto">
          ${content}
        </div>
      </body>
    </html>
  `;
}
