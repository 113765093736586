import { useGetChannelList } from '../../hooks/automated-campaign-query';
import { Spinner } from '@squantumengine/horizon';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { ChannelStatusEnum } from '../../shared/automated-campaign.interface';
import useCampaignParams from '../../hooks/useCampaignParams';

/* This route is created to support channel detail from outside MP
 * since the actual route might change over time
 */
const RedirectChannel = () => {
  const [searchParams] = useSearchParams();
  const { channelType, campaignId } = useCampaignParams();
  const navigate = useNavigate();

  const { data: channelList } = useGetChannelList(campaignId, {
    status: ChannelStatusEnum.UNDEFINED
  });

  useEffect(() => {
    if (!channelList) return;
    const params = searchParams.toString();

    if (!channelList.length) {
      navigate(`/campaign/manual`);
      return;
    }

    let filteredChannel = channelList.find(({ channel }) => channel.key === channelType);

    if (!filteredChannel) filteredChannel = channelList[0];

    navigate(`/campaign/manual/${filteredChannel.channel.key}/${filteredChannel.id}?${params}`);
  }, [channelList, channelType, navigate, searchParams]);

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div data-testid="redirect-channel-loader" className="flex flex-col text-center">
        <Spinner size="lg" />
      </div>
    </div>
  );
};

export default RedirectChannel;
