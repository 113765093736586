import { useMutation, useQueryClient } from 'react-query';
import { setCheckerReviewAction } from '../../api/checker-maker';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { ChannelKeyEnum } from '../../shared/master-api.interface';

export const useSetCheckerReviewAction = (channel: ChannelKeyEnum, reviewId: string = '') => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEYS_ENUM.setCheckerReviewAction],
    mutationFn: (action: 'approve' | 'decline') =>
      setCheckerReviewAction(channel, reviewId, action),
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(QUERY_KEYS_ENUM.getTemplateReviewList);
        queryClient.invalidateQueries(QUERY_KEYS_ENUM.getTemplateReviewCount);
      }, 500);
    }
  });
};
