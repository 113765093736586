import InstructionDesc from './instruction-desc';
import CodeCard from './code-card';
import { Dialog, DialogHeader, DialogBody } from '@squantumengine/horizon';
import './instruction-modal.css';
import { ChannelKeyEnum } from '../../../shared/master-api.interface';

interface CopyInstructionModalInterface {
  open: boolean;
  onCancel: () => void;
  curl: any;
  isCampaign?: boolean;
  channelType?: string;
}

function CopyInstructionModal({
  open,
  onCancel,
  curl,
  isCampaign,
  channelType
}: Readonly<CopyInstructionModalInterface>) {
  const curlHeader = curl?.split(/-d/g) || [];
  const curlData = '-d' + curlHeader?.pop();
  const contentId = isCampaign ? 'campaign_id' : 'channel_id';
  const contentCopy = isCampaign ? 'campaign' : 'content';

  const getChannelTypeLabel = () => {
    switch (channelType) {
      case ChannelKeyEnum.EMAIL:
        return 'Email';
      case ChannelKeyEnum.WHATSAPP:
        return 'WhatsApp';
      case ChannelKeyEnum.PUSH_NOTIFICATION:
        return 'Push Notification';
      default:
        return 'Email';
    }
  };
  return (
    <div className="custom-modal">
      <Dialog open={open} onClose={onCancel} className="overflow-hidden">
        <DialogHeader>
          <h2 className="m-0 p-0 text-2xl font-semibold">Code template</h2>
        </DialogHeader>
        <DialogBody className="flex max-h-[75vh] flex-col items-start gap-1 overflow-auto pt-0">
          <InstructionDesc
            no="1."
            desc="Copy dan kirim code ini ke engineer di business unit (BU) Anda masing-masing. Selain itu, beritahu apa saja dan kapan precoding ini akan digunakan. "
          />
          <CodeCard curl={curl} />
          <InstructionDesc no="2." desc="Pastikan code sudah sesuai ketentuan berikut: " />
          <CodeCard curl={curlHeader} />
          <div className="mb-6 flex flex-col pl-4 text-base">
            <div className="flex">
              <span>a.</span>
              <p className="m-0 ml-1">
                <span className="font-bold ">{contentId}</span>: Isi {contentId} sesuai dengan ID{' '}
                {contentCopy} yang ingin diluncurkan.
              </p>
            </div>
            <div className="flex">
              <span>b.</span>
              <p className="m-0 ml-1">
                <span className="font-bold ">x-client-id</span>: Isi dengan 16 karakter client ID.
              </p>
            </div>
            <div className="flex">
              <span>c.</span>
              <p className="m-0 ml-1">
                <span className="font-bold ">x-client-secret</span>: Isi dengan 16 karakter client
                secret.
              </p>
            </div>
          </div>
          <p className="m-0 pl-4 text-base">
            Sesuaikan bagian data sesuai kebutuhan {contentCopy} Anda
          </p>
          <CodeCard curl={curlData} />
          <div className="pl-4 text-base">
            <div className="flex">
              <span>a.</span>
              <p className="m-0 ml-1">
                <span className="font-bold ">receiver_{channelType}</span>
                {`: Diisi dengan
                ${getChannelTypeLabel()} 
                penerima`}
              </p>
            </div>
            <div className="flex">
              <span>b.</span>
              <p className="m-0 ml-1">
                <span className="font-bold ">precodings</span>:
              </p>
            </div>
          </div>
          <div className="pl-8 text-base">
            <div className="flex">
              <span>i.</span>
              <p className="m-0 ml-1">
                <span className="font-bold ">key</span>: Diisi dengan nama precoding yang terdapat
                pada template.
              </p>
            </div>
            <div className="flex">
              <span>ii.</span>
              <p className="m-0 ml-1">
                <span className="font-bold ">value</span>: diisi dengan nilai precoding yang
                dimaksud.
              </p>
            </div>
          </div>
          <InstructionDesc no="3." desc="Limitasi yang perlu diketahui sebelum mengirim: " />
          <div className="pl-4">
            <InstructionDesc no="a." desc="Lampiran email" />
          </div>
          <div className="pl-8 text-base">
            <div className="flex">
              <span>i.</span>
              <p className="m-0 ml-1">Maksimum ukuran lampiran adalah 10 MB.</p>
            </div>
            <div className="flex">
              <span>ii.</span>
              <p className="m-0 ml-1">
                Tidak ada jumlah maksimum lampiran selama tidak melebihi 10 MB.
              </p>
            </div>
            <div className="flex">
              <span>iii.</span>
              <p className="m-0 ml-1">
                Format lampiran yang diterima: jpeg, jpg, png, gif, txt, zip, rar, pdf.
              </p>
            </div>
            <div className="flex">
              <span>iv.</span>
              <p className="m-0 ml-1">Hanya pdf yang bisa diproteksi dengan password.</p>
            </div>
          </div>
          <InstructionDesc
            no="4."
            desc="MP akan mengirimkan balasan. Berikut penjelasan dari balasan yang mungkin diterima:"
          />
          <div className="flex pl-4 text-base">
            <span>a. </span>
            <p className="m-0 ml-1">
              <span className="font-bold ">delivery_status</span> dapat berupa:
            </p>
          </div>
          <div className="pl-8 text-base">
            <div className="flex">
              <span>i.</span>
              <p className="m-0 ml-1">
                <span className="font-bold ">delivered</span>: Berhasil dikirim.{' '}
              </p>
            </div>
            <div className="flex">
              <span>ii.</span>
              <p className="m-0 ml-1">
                <span className="font-bold ">opened</span>: Email telah dibuka oleh customer.
              </p>
            </div>
            <div className="flex">
              <span>iii.</span>
              <p className="m-0 ml-1">
                <span className="font-bold ">in_queue</span>: Telah masuk di dalam antrian sistem
                dan akan dikirim.
              </p>
            </div>
            <div className="flex">
              <span>iv.</span>
              <p className="m-0 ml-1">
                <span className="font-bold ">invalid_receiver</span>: Email atau domain tidak valid.
              </p>
            </div>
            <div className="flex">
              <span>v.</span>
              <p className="m-0 ml-1">
                <span className="font-bold ">no_template</span>: Tidak memiliki template.
              </p>
            </div>
            <div className="flex">
              <span>vi.</span>
              <p className="m-0 ml-1">
                <span className="font-bold ">spam_reject</span>: Terdeteksi sebagai spam. Tinjau
                ulang template yang telah dibuat.
              </p>
            </div>
            <div className="flex">
              <span>vii.</span>
              <p className="m-0 ml-1">
                <span className="font-bold ">unknown_error</span>: Terjadi eror. Anda dapat mencoba
                lagi dalam beberapa saat.
              </p>
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </div>
  );
}

export default CopyInstructionModal;
