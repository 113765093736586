import { PushNotificationEditorContextInterface } from '../context/push-notification-editor-context.interface';
import { CampaignTypeEnum, ChannelStatusEnum } from '../shared/automated-campaign.interface';
import { PrecodingInterface } from '../shared/precoding.interface';
import {
  PushNotificationChannelPayloadInterface,
  PushNotificationChannelResponseInterface
} from '../shared/push-notification-configuration.interface';
import { parseEditorToString } from './lexical';
import { precodingArrayToMap, precodingMapToArray } from './precoding';

export function normalizeNotificationContext(
  campaignStatus: ChannelStatusEnum,
  data: PushNotificationEditorContextInterface,
  campaignType: CampaignTypeEnum
): PushNotificationChannelPayloadInterface {
  const header = parseEditorToString(data.headerEditor);
  const body = parseEditorToString(data.bodyEditor);

  const contentJson = {
    header: data.headerEditor.getEditorState().toJSON(),
    body: data.bodyEditor.getEditorState().toJSON()
  };

  return {
    name: data.name,
    description: data.description,
    sender: {
      id: data.senderId as string
    },
    type: campaignType,
    status: campaignStatus,
    template: {
      category: data.category,
      header,
      body,
      deeplink: data.deepLink,
      precoding_example: precodingMapToArray(data.precodingMap),
      image: {
        id: data.image?.id ?? ''
      },
      content_json: JSON.stringify(contentJson),
      is_silence: data.isSilence,
      is_action_required: data.isActionRequired,
      button_cta: data.buttonCta
    }
  };
}

export function initializeNotificationContext(
  context: PushNotificationEditorContextInterface,
  data: PushNotificationChannelResponseInterface['data']
) {
  context.setInitialData(data);
  context.setCategory(data.template.category);
  context.setSenderId(data.sender.id);
  context.setName(data.name);
  context.setDescription(data.description);
  context.setDeepLink(data.template.deeplink);
  context.setIsSilence(data.template.is_silence);
  context.setIsActionRequired(data.template.is_action_required);
  context.setStatus(data.status);
  // intentionally uses || instead of ?? to handle empty string from BE
  context.setButtonCta(data.template.button_cta || undefined);

  if (data.template.image?.name) {
    context.setImage({
      name: data.template.image.name,
      id: data.template.image.id,
      url: data.template.image.download_url
    });
  }

  const { header, body } = JSON.parse(data.template.content_json);

  const headerEditorJSONState = context.headerEditor.parseEditorState(header);
  context.headerEditor.setEditorState(headerEditorJSONState);

  const bodyEditorJSONState = context.bodyEditor.parseEditorState(body);
  context.bodyEditor.setEditorState(bodyEditorJSONState);

  context.setPrecodingMap(
    precodingArrayToMap(data.template.precoding_example as PrecodingInterface[])
  );
}
