import { Button, Paragraph } from '@squantumengine/horizon';
import { Link } from 'react-router-dom';
import AndroidFrame from '../../assets/image/android-frame.png';
import IosFrame from '../../assets/image/ios-frame.png';
import { usePushNotificationEditorContext } from '../../context/push-notification-editor-context';
import { AuthActionScopeEnum } from '../../shared/user.interface';
import { recolorAllPrecodingBrackets } from '../../utils/lexical';
import Checker from '../checker';
import PreviewContainer from '../push-notification-preview/preview-container';
import PreviewContentDefault from '../push-notification-preview/preview-content-default';
import PreviewContentFull from '../push-notification-preview/preview-content-full';
import { DeviceEnum } from '../push-notification-preview/push-notification-preview.interface';
import useInitializeTemplate from '../push-notification/useInitializeTemplate';
import ScopeWrapper from '../role-wrapper/scope-wrapper';
import CampaignDetailsTemplate from './campaign-details-template';
import { ChannelKeyEnum } from '../../shared/master-api.interface';
import { isTestButtonEnabled } from '../../utils/automatedCampaign';
import useCampaignParams from '../../hooks/useCampaignParams';
import { useGetChannelList } from '../../hooks/automated-campaign-query/useGetChannelList';

interface IPreviewContent {
  image?: string;
  header: string;
  body: string;
  button?: string;
  isFull?: boolean;
}

function PreviewContent({ isFull, ...restProps }: Readonly<IPreviewContent>) {
  return (
    <div className="flex gap-6">
      <div className="w-[290px]">
        <PreviewContainer image={AndroidFrame} title="Android">
          {isFull ? (
            <PreviewContentFull {...restProps} device={DeviceEnum.IOS} />
          ) : (
            <PreviewContentDefault {...restProps} />
          )}
        </PreviewContainer>
      </div>
      <div className="w-[290px]">
        <PreviewContainer image={IosFrame} title="iOS">
          {isFull ? (
            <PreviewContentFull {...restProps} device={DeviceEnum.IOS} />
          ) : (
            <PreviewContentDefault {...restProps} />
          )}
        </PreviewContainer>
      </div>
    </div>
  );
}

function NotificationContent() {
  const { channelId, campaignType, channelType } = useCampaignParams();

  const {
    headerContent,
    bodyContent,
    image,
    buttonCta,
    initialData: dataChannel,
    status,
    setReadOnly
  } = usePushNotificationEditorContext();

  useInitializeTemplate(channelId);

  const testButtonEnabled = isTestButtonEnabled(status);

  const { campaign_id: campaignId } = dataChannel ?? {};

  const { data: channelListData } = useGetChannelList(campaignId ?? '');

  return (
    <CampaignDetailsTemplate
      status={status}
      channelType={ChannelKeyEnum.PUSH_NOTIFICATION}
      headerTitle={dataChannel?.name ?? 'Detail Push Notification Konten Campaign'}
      broadcastCheckerPrefix="pushNotificationDetail"
      contentDetail={{
        title: dataChannel?.name,
        description: dataChannel?.description || '-',
        senderNumber: dataChannel?.sender.name,
        category: dataChannel?.template.category,
        channelList: channelListData ?? [],
        campaignId: campaignId
      }}
      actionButtons={
        <>
          <ScopeWrapper scope={AuthActionScopeEnum.CHANNEL_PN_WRITE}>
            <Link to={`/campaign/${campaignType}/${channelType}/${channelId}/edit`}>
              <Button variant="secondary" onClick={() => setReadOnly(false)}>
                Edit
              </Button>
            </Link>
          </ScopeWrapper>
          {testButtonEnabled && (
            <ScopeWrapper scope={AuthActionScopeEnum.CHANNEL_PN_TEST}>
              <Link to={`/campaign/${campaignType}/${channelType}/${channelId}/test`}>
                <Button className="text-nowrap">Tes Notifikasi</Button>
              </Link>
            </ScopeWrapper>
          )}
        </>
      }>
      <div className="flex flex-col">
        <Checker anchorPrefix="pushNotification" isDynamic>
          <div className="flex flex-col rounded-lg bg-white p-6 shadow-lg shadow-black-90/5">
            <div className="flex flex-col gap-6">
              <Paragraph className="text-base font-semibold">Default Preview</Paragraph>
              <PreviewContent
                header={recolorAllPrecodingBrackets(headerContent)}
                body={recolorAllPrecodingBrackets(bodyContent)}
                image={image?.url}
                button={buttonCta}
              />
            </div>
            <div className="my-6 h-[1px] w-full bg-neutral-100" />
            <div className="flex flex-col gap-6">
              <Paragraph className="text-base font-semibold">Tampilan Penuh</Paragraph>
              <PreviewContent
                header={recolorAllPrecodingBrackets(headerContent)}
                body={recolorAllPrecodingBrackets(bodyContent)}
                image={image?.url}
                button={buttonCta}
                isFull
              />
            </div>
          </div>
        </Checker>
      </div>
    </CampaignDetailsTemplate>
  );
}

export default NotificationContent;
