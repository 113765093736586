import { useQuery } from 'react-query';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { getEmailBroadcastDetail } from '../../api/broadcast';

export const useGetEmailBroadcastDetail = (id: string, enabled: boolean) => {
  return useQuery({
    queryKey: [QUERY_KEYS_ENUM.getEmailBroadcastDetail, id],
    enabled: !!id && enabled,
    queryFn: () => getEmailBroadcastDetail(id)
  });
};
