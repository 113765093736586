import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useModal from '../../../hooks/useModal';
import {
  CampaignTypeEnum,
  ChannelListInterface,
  ChannelStatusEnum
} from '../../../shared/automated-campaign.interface';
import {
  useGetAutomatedCampaignDetail,
  useGetChannelList
} from '../../../hooks/automated-campaign-query';
import { CampaignDetailContextInterface } from './campaign-detail-context.interface';
import { useCopyToClipboard } from '../../../hooks/useCopyToClipboard';
import { CampaignStatusEnum } from '../campaign-detail.helper';
import { QUERY_KEYS_ENUM } from '../../../static/query-keys.enum';
import { useQueryClient } from 'react-query';
import useCampaignParams from '../../../hooks/useCampaignParams';

export const CampaignDetailContext = createContext<CampaignDetailContextInterface>(
  {} as CampaignDetailContextInterface
);

export const CampaignDetailProvider = ({ children }: { children: React.ReactNode }) => {
  const queryClient = useQueryClient();
  const [openDrawer, setOpenDrawer] = useState(false);
  const { copy } = useCopyToClipboard();
  const { visible, closeModal, openModal } = useModal();
  const { visible: visibleCode, closeModal: closeModalCode, openModal: openModalCode } = useModal();
  const [status, setStatus] = useState<ChannelStatusEnum>(ChannelStatusEnum.UNDEFINED);
  const { campaignId } = useCampaignParams();
  const [campaignStatus, setCampaignStatus] = useState<CampaignStatusEnum>(
    CampaignStatusEnum.ACTIVE
  );

  const { data: campaignDetail, isLoading: campaignDetailLoading } =
    useGetAutomatedCampaignDetail(campaignId);

  const curl = campaignDetail?.example_code.curl;
  const paramsChannelList = { status };

  const channelListData = useGetChannelList(campaignId, paramsChannelList);
  const {
    data: channelListArray = [],
    isLoading: channelListLoading,
    refetch: refetchChannelList
  } = channelListData;

  const handleOpenDrawer = useCallback(() => {
    setOpenDrawer(true);
  }, []);

  const handleCloseDrawer = useCallback(() => {
    setOpenDrawer(false);
  }, []);

  useEffect(() => {
    // to prevent unshown data due to BE delay, retry fetching channel list after 2 seconds
    const timeout = setTimeout(() => {
      queryClient.invalidateQueries(QUERY_KEYS_ENUM.channelListAutomated);
    }, 2000);

    return () => clearTimeout(timeout);
  }, [queryClient]);

  const onChangeFilter = (status: ChannelStatusEnum) => {
    setStatus(status);
  };

  useEffect(() => {
    if (campaignDetail) setCampaignStatus(campaignDetail.status as CampaignStatusEnum);
  }, [campaignDetail]);

  const channelList = useMemo(() => {
    const clonedArray = [...channelListArray];

    clonedArray.sort((a, b) => {
      const getStatus = (el: ChannelListInterface) => (el.status === 'active' ? 1 : 0);
      const statusComparison = getStatus(b) - getStatus(a);
      const updatedAtComparison = b.updated_at - a.updated_at;
      return statusComparison || updatedAtComparison;
    });

    return clonedArray;
  }, [channelListArray]);

  const campaignType = campaignDetail?.type || CampaignTypeEnum.AUTOMATED;

  const contextValue = useMemo(
    () => ({
      curl,
      status,
      openDrawer,
      campaignId,
      campaignType,
      campaignDetail,
      campaignDetailLoading,
      channelList,
      channelListLoading,
      visible,
      visibleCode,
      closeModal,
      closeModalCode,
      openModalCode,
      openModal,
      onChangeFilter,
      copy,
      campaignStatus,
      refetchChannelList,
      handleOpenDrawer,
      handleCloseDrawer
    }),
    [
      curl,
      status,
      openDrawer,
      campaignId,
      campaignDetail,
      campaignType,
      campaignDetailLoading,
      channelList,
      channelListLoading,
      visible,
      visibleCode,
      closeModal,
      closeModalCode,
      openModalCode,
      openModal,
      copy,
      campaignStatus,
      refetchChannelList,
      handleOpenDrawer,
      handleCloseDrawer
    ]
  );
  return (
    <CampaignDetailContext.Provider value={contextValue}>{children}</CampaignDetailContext.Provider>
  );
};

export const useCampaignDetailContext = () => useContext(CampaignDetailContext);
