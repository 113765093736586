import { useQuery } from 'react-query';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { getPushNotifBroadcastDetail } from '../../api/broadcast';

export const useGetPushNotifBroadcastDetail = (id: string, enabled: boolean) => {
  return useQuery({
    queryKey: [QUERY_KEYS_ENUM.getPushNotifBroadcastDetail, id],
    enabled: !!id && enabled,
    queryFn: () => getPushNotifBroadcastDetail(id)
  });
};
