import { Button, Paragraph } from '@squantumengine/horizon';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { useGenerateHtmlContent } from '../../hooks/useGenerateHtmlContent';
import { baseEmailData } from '../../pages/test-channel/test-email';
import { AuthActionScopeEnum } from '../../shared/user.interface';
import { parsePrecodingInString } from '../../utils/lexical';
import { precodingArrayToMap } from '../../utils/precoding';
import Checker from '../checker';
import { parseTemplateWidth } from '../rich-text/editor/hooks/useInitialHtml';
import { attachmentListToMap } from '../rich-text/plugins/AttachmentPlugin/attachment-plugin.helper';
import AttachmentPreviewGrid from '../rich-text/plugins/AttachmentPlugin/attachment-preview-grid';
import ScopeWrapper from '../role-wrapper/scope-wrapper';
import CampaignDetailsTemplate from './campaign-details-template';
import { ChannelKeyEnum } from '../../shared/master-api.interface';
import { isTestButtonEnabled } from '../../utils/automatedCampaign';
import useCampaignParams from '../../hooks/useCampaignParams';
import { useGetChannelList, useGetEmailChannelDetail } from '../../hooks/automated-campaign-query';

function EmailContent() {
  const navigate = useNavigate();
  const { channelId, campaignType, channelType } = useCampaignParams();
  const { data = baseEmailData, isLoading } = useGetEmailChannelDetail(channelId);

  const {
    content,
    width: configurationWidth,
    precoding_example,
    preheader,
    subject
  } = data?.template ?? {};

  const { campaign_id: campaignId } = data;

  const { data: channelListData } = useGetChannelList(campaignId ?? '');
  const htmlString = content;
  const width = parseTemplateWidth(configurationWidth);
  const { renderedHtml } = useGenerateHtmlContent({
    htmlString: htmlString ?? '',
    width
  });

  const handleTestTemplate = () =>
    navigate(`/campaign/${campaignType}/${channelType}/${channelId}/test`);
  const handleEditTemplate = () =>
    navigate(`/campaign/${campaignType}/${channelType}/${channelId}/edit`);

  const precodingMap = useMemo(
    () => precodingArrayToMap(precoding_example || []),
    [precoding_example]
  );

  const attachments = useMemo(() => data && attachmentListToMap(data.attachment), [data]);

  const testButtonEnabled = isTestButtonEnabled(data?.status);

  return (
    <CampaignDetailsTemplate
      status={data.status}
      channelType={ChannelKeyEnum.EMAIL}
      headerTitle={data?.name ?? 'Detail Email Konten Campaign'}
      broadcastCheckerPrefix="emailDetail"
      isLoading={isLoading}
      contentDetail={{
        title: data?.name,
        description: data?.description || '-',
        senderNumber: data?.sender.email,
        category: data?.template.category,
        channelList: channelListData,
        campaignId: campaignId
      }}
      actionButtons={
        <>
          <ScopeWrapper scope={AuthActionScopeEnum.CHANNEL_EMAIL_WRITE}>
            <Button variant="secondary" className="min-w-52" onClick={handleEditTemplate}>
              Edit
            </Button>
          </ScopeWrapper>
          {testButtonEnabled && (
            <ScopeWrapper scope={AuthActionScopeEnum.CHANNEL_EMAIL_TEST}>
              <Button variant="primary" onClick={handleTestTemplate}>
                Tes Email
              </Button>
            </ScopeWrapper>
          )}
        </>
      }
    >
      <div className="flex flex-col gap-12 rounded-lg bg-white shadow-lg shadow-black-90/5">
        <Checker anchorPrefix="email" isDynamic>
          <div className="p-6">
            <Paragraph className="text-lg font-semibold">
              <span
                dangerouslySetInnerHTML={{
                  __html: parsePrecodingInString(subject, precodingMap) || 'Subjek Email'
                }}
              />
            </Paragraph>
            {preheader && (
              <Paragraph className="text-base font-normal">
                <span
                  dangerouslySetInnerHTML={{
                    __html: parsePrecodingInString(preheader, precodingMap)
                  }}
                />
              </Paragraph>
            )}
          </div>
          <div className="h-[1px] w-full bg-neutral-200" />
          <div className="p-6">
            <div className="flex items-center justify-center px-6 py-4">
              <div className="preview-email-content-container w-full">
                <div
                  dangerouslySetInnerHTML={{
                    __html: parsePrecodingInString(renderedHtml, precodingMap)
                  }}
                />
              </div>
            </div>
            {attachments?.size ? (
              <div className="border-0 border-t border-solid border-grey-50 p-6">
                <AttachmentPreviewGrid attachments={attachments} />
              </div>
            ) : null}
          </div>
        </Checker>
      </div>
    </CampaignDetailsTemplate>
  );
}
export default EmailContent;
