import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { CheckerContextInterface, CommentAnchorType, CommentInterface } from './checker.interface';
import { usePostComment } from '../../hooks/checker-maker-query/usePostComment';
import { useGetCommentList } from '../../hooks/checker-maker-query/useGetCommentList';
import { useEditComment } from '../../hooks/checker-maker-query/useEditComment';
import { useResolveComment } from '../../hooks/checker-maker-query/useResolveComment';
import { useDeleteComment } from '../../hooks/checker-maker-query/useDeleteComment';
import { useGetTemplateDetail } from './useGetTemplateDetail';
import { CommentVisibilityEnum } from '../../static/cheker-maker.enum';
import { useGetActivityList } from '../../hooks/checker-maker-query/useGetActivityList';
import { ChannelKeyEnum } from '../../shared/master-api.interface';
import { generateActivityHistory } from './checker.utils';
import {
  CheckerMakerReviewStatusEnum,
  GetTemplateDetailDataResponse
} from '../../shared/checker-maker.interface';
import { useGetBroadcastDetails } from './useGetBroadcastDetails';

export const CheckerContext = createContext<CheckerContextInterface>({} as CheckerContextInterface);

export const CheckerProvider = ({
  children,
  initialDisabled = false,
  channelType
}: {
  children: React.ReactNode;
  initialDisabled?: boolean;
  channelType: ChannelKeyEnum;
}) => {
  const [commentAnchors, setCommentAnchors] = useState<CommentAnchorType[]>([]);
  const [commentMap, setCommentMap] = useState<Map<CommentAnchorType, CommentInterface>>(new Map());
  const [selectedCommentAnchor, setSelectedCommentAnchor] = useState<CommentAnchorType | null>(
    null
  );

  const [disabled, setDisabled] = useState<boolean>(initialDisabled);
  const { data } = useGetTemplateDetail(channelType);
  const { mutate, isLoading: isLoadingPost } = usePostComment();
  const { mutate: editComment, isLoading: isLoadingEdit } = useEditComment();
  const { mutate: deleteComment, isLoading: isLoadingDelete } = useDeleteComment();
  const { mutate: resolve, isLoading: isLoadingResolve } = useResolveComment();
  const { data: broadcastData } = useGetBroadcastDetails();
  const isLoadingAction = isLoadingPost || isLoadingEdit || isLoadingDelete || isLoadingResolve;

  const reviewId = (data as GetTemplateDetailDataResponse)?.data?.id;
  const canBeReviewed =
    (data as GetTemplateDetailDataResponse)?.data?.status === CheckerMakerReviewStatusEnum.REVIEW;

  useGetCommentList(
    { id: reviewId ?? '', page: 1, limit: 100 },
    {
      onSuccess: (data) => {
        const { data: comments } = data;
        const anchors = comments.map((comment) => comment.anchor);
        setCommentAnchors(anchors);

        setCommentMap((prev) => {
          const newMap = new Map(prev);
          for (const comment of comments) {
            if (comment.visibility === CommentVisibilityEnum.HIDDEN) continue;
            newMap.set(comment.anchor, {
              anchor: comment.anchor,
              value: comment.text,
              html: comment.highlight,
              id: comment.id,
              status: comment.status,
              createdBy: comment.created_by
            });
          }
          return newMap;
        });
      }
    }
  );

  const { data: activityList } = useGetActivityList(channelType, reviewId);

  const activityData = useMemo(
    () => generateActivityHistory(activityList?.data || []),
    [activityList]
  );

  const addComment = useCallback(
    ({ anchor, value, html }: CommentInterface) => {
      const newComment: CommentInterface = { anchor, value, html };
      if (!reviewId) return;
      mutate(
        { id: reviewId, text: value, anchor, highlight: html ?? '' },
        {
          onSuccess: () => {
            setCommentAnchors((prev) => [...prev, anchor]);
            setCommentMap((prev) => new Map(prev.set(anchor, newComment)));
          }
        }
      );
    },
    [reviewId, mutate]
  );

  const removeComment = useCallback(
    (anchor: CommentAnchorType, id: string) => {
      deleteComment(id, {
        onSuccess: () => {
          setCommentAnchors((prev) => prev.filter((commentAnchor) => commentAnchor !== anchor));
          setCommentMap((prev) => {
            prev.delete(anchor);
            return new Map(prev);
          });
        }
      });
    },
    [deleteComment]
  );

  const updateComment = useCallback(
    ({ anchor, value, html, id }: CommentInterface) => {
      if (!id) return;
      editComment(
        { id, text: value, anchor, highlight: html ?? '' },
        {
          onSuccess: () => {
            setCommentMap((prev) => {
              const comment = prev.get(anchor);
              if (!comment) return prev;
              comment.value = value;
              comment.html = html;
              return new Map(prev.set(anchor, comment));
            });
          }
        }
      );
    },
    [editComment]
  );

  const resolveComment = useCallback(
    (id: string) => {
      resolve(id);
    },
    [resolve]
  );

  useEffect(() => {
    // prevent overflow to hide global scrollbar due to the absolute comment boxes
    const htmlContainerElement = document.documentElement.style;
    const originalStyle = document.documentElement.style.overflow;
    htmlContainerElement.overflow = 'hidden';

    return () => {
      htmlContainerElement.overflow = originalStyle;
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      commentAnchors,
      commentMap,
      disabled,
      setDisabled,
      addComment,
      removeComment,
      updateComment,
      selectedCommentAnchor,
      setSelectedCommentAnchor,
      resolveComment,
      isLoadingAction,
      broadcastData,
      reviewId,
      activityData,
      canBeReviewed,
      channelType
    }),
    [
      commentAnchors,
      commentMap,
      addComment,
      removeComment,
      updateComment,
      selectedCommentAnchor,
      disabled,
      resolveComment,
      isLoadingAction,
      broadcastData,
      reviewId,
      activityData,
      canBeReviewed,
      channelType
    ]
  );

  return <CheckerContext.Provider value={contextValue}>{children}</CheckerContext.Provider>;
};

export const useCheckerContext = () => useContext(CheckerContext);
