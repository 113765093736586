import { NoticeType } from 'antd/es/message/interface';
import { useQuery } from 'react-query';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { getWhatsAppSenderList } from '../../api/whatsapp';
import { getErrorMessage } from '../../utils/error';

export const useGetWhatsAppSenderList = (
  toastMessage?: (type: NoticeType, message: string) => void
) => {
  return useQuery({
    queryKey: [QUERY_KEYS_ENUM.getWhatsAppSenderList],
    queryFn: () => getWhatsAppSenderList(),
    select: ({ data }) => {
      return data;
    },
    onError: (error: any) => {
      toastMessage?.('error', `Gagal memuat data pengirim: ${getErrorMessage(error)}`);
    }
  });
};
