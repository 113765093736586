import { NoticeType } from 'antd/lib/message/interface';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { useMutation, useQueryClient } from 'react-query';
import { editEmailChannel } from '../../api/automated-campaign';
import { CreateEmailChannelPayload } from '../../shared/automated-campaign.interface';
import { handleAddNewChannelError } from './useCreateEmailContent';

export const useEditEmailContent = (
  id: string,
  handleError: (value: boolean) => void,
  toastMessage: (type: NoticeType, message: string) => void,
  onSuccess?: () => void
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEYS_ENUM.editChannel, id],
    mutationFn: (data: CreateEmailChannelPayload) => editEmailChannel(id, data),
    onSuccess: () => {
      queryClient.invalidateQueries([QUERY_KEYS_ENUM.getEmailTemplate, id]);
      queryClient.invalidateQueries(QUERY_KEYS_ENUM.channelListAutomated);
      queryClient.invalidateQueries(QUERY_KEYS_ENUM.getEmailDetail);
      queryClient.invalidateQueries(QUERY_KEYS_ENUM.getChannelList);

      handleError(false);
      onSuccess?.();
    },
    onError: handleAddNewChannelError(toastMessage)
  });
};
