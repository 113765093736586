import axios from 'axios';
import axiosInstance from '../config/axios/axiosWithToken';
import {
  UploadEmailAttachmentResponse,
  UploadEmailAttachmentStatusResponse,
  UploadImageProps,
  UploadImageResponseProps,
  UploadPushNotifImageProps,
  UploadPushNotifImageResponseProps
} from '../shared/file-upload.interface';

export const uploadImage: (data: UploadImageProps) => Promise<UploadImageResponseProps> = async (
  data
) => {
  const response = await axiosInstance.post('/image', data);
  return response.data;
};

export const presignedUrl: (params: { file_type: string }) => Promise<any> = async (params) => {
  const response = await axiosInstance.get(`/file/presigned-url`, { params });
  return response.data;
};

export const pushNotifPresignedUrl: (
  params: UploadPushNotifImageProps
) => Promise<UploadPushNotifImageResponseProps> = async (params) => {
  const response = await axiosInstance.post(`/push_notification/upload_notification_image`, params);
  return response.data;
};

export const uploadFile: (data: any, url: string, contentType: string) => Promise<any> = async (
  data,
  url,
  contentType
) => {
  const response = await axios.put(url, data, {
    headers: {
      'Content-Type': contentType
    }
  });
  return response.data;
};

export const submitUrlFile: (payload: { url: string; thumbnail: string }) => Promise<any> = async (
  payload
) => {
  const response = await axiosInstance.post('/file/url', payload);
  return response.data;
};

export const getWhatsAppThumbnail: (id: string) => Promise<any> = async (id) => {
  const response = await axiosInstance.get(`whatsapp/media/${id}`);
  return response.data;
};

export const getEmailPresignedUrl: (payload: {
  extension: string;
  name: string;
}) => Promise<UploadEmailAttachmentResponse> = async ({ extension, name }) => {
  const response = await axiosInstance.get(`/email/signed_url?extension=${extension}&name=${name}`);
  return response.data;
};

export const getEmailAttachmentPresignedUrl: (payload: {
  extension: string;
  name: string;
}) => Promise<UploadEmailAttachmentResponse> = async ({ extension, name }) => {
  const response = await axiosInstance.get(
    `/email/attachment/signed_url?extension=${extension}&name=${name}`
  );
  return response.data;
};

export const getEmailAttachmentUploadStatus: (
  id: string
) => Promise<UploadEmailAttachmentStatusResponse> = async (id) => {
  const response = await axiosInstance.get(`email/attachment/${id}`);
  return response.data.data;
};

export const getEmailDownloadUrl: (id: string) => Promise<any> = async (id) => {
  const response = await axiosInstance.get(`email/media/${id}`);
  return response.data.data;
};
