import { useNavigate } from 'react-router';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { useQuery } from 'react-query';
import { fetchEmailChannel } from '../../api';
import { redirectTo404 } from '../../utils/error';

interface useGetEmailChannelDetailProps {
  enabled?: boolean;
}

export const useGetEmailChannelDetail = (
  id: string = '',
  { enabled = true }: useGetEmailChannelDetailProps = {}
) => {
  const navigate = useNavigate();
  return useQuery({
    queryKey: [QUERY_KEYS_ENUM.channelDetail, id],
    queryFn: () => fetchEmailChannel(id),
    enabled: !!id && enabled,
    select: ({ data }) => {
      return data;
    },
    onError: redirectTo404(navigate),
    retry: 1
  });
};
