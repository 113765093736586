import { Dialog, Label, Spinner } from '@squantumengine/horizon';
import { ReactNode, useCallback, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppConfig } from '../../hooks/useAuth';
import { generateChannelStatusValue } from '../../utils/automatedCampaign';
import Checker from '../checker';
import DetailBroadcast from './detail-broadcast';
import CampaignHeader from './header';
import HeaderActionComment from './header-action-comment';
import { parseUnixDateInMs } from '../../utils/date';
import { IMetaContent } from './content-info';
import { useCheckerContext } from '../checker/checker-context';
import { ChannelStatusEnum } from '../../shared/automated-campaign.interface';
import { ChannelKeyEnum } from '../../shared/master-api.interface';
import { Spin } from 'antd';
import { BroadcastDataInterface } from '../../shared/checker-maker.interface';
import useCampaignParams from '../../hooks/useCampaignParams';
import ChannelActionComponent from '../channel-action-component';

interface CampaignDetailsTemplateProps {
  children: ReactNode;
  broadcastCheckerPrefix: string;
  actionButtons: ReactNode;
  headerTitle: string;
  contentDetail: Partial<IMetaContent>;
  status?: ChannelStatusEnum;
  channelType: ChannelKeyEnum;
  isLoading?: boolean;
}

const dayOfWeek = {
  0: 'Senin',
  1: 'Selasa',
  2: 'Rabu',
  3: 'Kamis',
  4: 'Jumat',
  5: 'Sabtu',
  6: 'Minggu'
};

const stringifyMultipleElement = (elements: any[]) => {
  if (elements.length === 1) return elements[0];
  const elementsClone = [...elements];
  const lastElement = elementsClone.pop();
  return `${elementsClone.join(', ')} dan ${lastElement}`;
};

const generateRecurringBroadcastData = (data: BroadcastDataInterface) => {
  if (!data.interval) return '';

  const recurringEnd = data.end_at
    ? ' (hingga ' + parseUnixDateInMs(Number(data.end_at)).format('DD MMM YYYY') + ')'
    : '';

  switch (data.interval) {
    case 'daily':
      return `Setiap hari${recurringEnd}`;
    case 'weekly':
      return `Mingguan: ${stringifyMultipleElement(
        data.day_of_week
          // shifts Sunday (0) to the last (due to human order)
          .map((day) => (+day + 6) % 7)
          .sort((a, b) => a - b)
          .map((day) => dayOfWeek[day as keyof typeof dayOfWeek])
      )}${recurringEnd}`;
    case 'monthly':
      // the data can be in string from BE, and needs to be sorted
      const arr = data.day_of_month
        .map(Number)
        .sort((a, b) => a - b)
        .map(String);
      if (data.is_end_of_month) arr.push('Akhir bulan');
      return `Bulanan: ${stringifyMultipleElement(data.day_of_month)}${recurringEnd}`;
  }
};

function CampaignDetailsTemplate({
  children,
  broadcastCheckerPrefix,
  actionButtons,
  headerTitle,
  contentDetail,
  status,
  channelType,
  isLoading
}: Readonly<CampaignDetailsTemplateProps>) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const isFromReviewPage = searchParams.get('ref') === 'approval';
  const { campaignType } = useCampaignParams();
  const { isRoleChecker, isRoleMaker, isCheckerMaker } = useAppConfig();
  const { disabled, setDisabled, isLoadingAction, broadcastData, activityData, reviewId } =
    useCheckerContext();

  const onBack = useCallback(() => {
    if (!disabled) {
      setDisabled(!disabled);
      return;
    }

    const toReviewPage = isCheckerMaker && isFromReviewPage;
    navigate(toReviewPage ? '/campaign/waiting-for-approval' : `/campaign/${campaignType}`);
  }, [isCheckerMaker, campaignType, disabled, navigate, setDisabled, isFromReviewPage]);

  const metaContent = useMemo(() => {
    return {
      content: contentDetail,
      broadcast: broadcastData
        ? {
            date: parseUnixDateInMs(Number(broadcastData.next_execution_at)).format(
              'DD MMM YYYY, HH:mm:ss'
            ),
            contacts: broadcastData.contact.map((item: any) => item.name),
            editor: broadcastData.creator,
            recurring: generateRecurringBroadcastData(broadcastData),
            history: activityData
          }
        : undefined
    };
  }, [contentDetail, broadcastData, activityData]);
  const statusValue = generateChannelStatusValue(status ?? '', channelType);

  if (isLoading) {
    return (
      <div data-testid="loading" className="flex h-[70vh] items-center justify-center">
        <Spin size="large" className="pt-20" />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-6 px-7 py-8">
      <CampaignHeader
        title={headerTitle}
        onClickBack={onBack}
        tags={
          <div className="flex flex-col gap-4 desktop-sm:flex-row">
            <Label border type={statusValue.color as any} label={statusValue.label} />
          </div>
        }>
        <div className="flex flex-col gap-4 desktop-sm:flex-row">
          {isCheckerMaker && isRoleChecker && <HeaderActionComment />}
          {(!isCheckerMaker || isRoleMaker) && actionButtons}
        </div>
      </CampaignHeader>
      <ChannelActionComponent metaContent={metaContent} isMarketing={Boolean(reviewId)} />
      <div className="grid grid-cols-3 items-start gap-6">
        <Checker anchorPrefix={broadcastCheckerPrefix}>
          <DetailBroadcast {...metaContent} anchorPrefix={broadcastCheckerPrefix} />
        </Checker>
        <div className="col-span-2 flex items-start">{children}</div>
      </div>
      <Dialog open={isLoadingAction} onClose={() => {}} hideCloseBtn className="bg-transparent">
        <Spinner />
      </Dialog>
    </div>
  );
}

export default CampaignDetailsTemplate;
