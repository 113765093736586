export enum QUERY_KEYS_ENUM {
  senderList = 'sender-list',
  automatedCampaignDetail = 'automated-campaign-detail',
  channelListAutomated = 'channel-list-automated',
  addNewChannel = 'add-new-channel',
  automatedCampaignList = 'automated-campaign-list',
  editAutomatedCampaign = 'edit-automated-campaign',
  editAutomatedCampaignChannel = 'edit-automated-campaign-channel',
  addAutomatedCampaign = 'add-automated-campaign',
  getEmailTemplate = 'get-email-template',
  editEmailTemplate = 'edit-email-template',
  uploadImage = 'upload-image',
  channelAnalytics = 'channel-analytics',
  channelDetail = 'channel-detail',
  editChannel = 'edit-channel',
  getAnalyticsSummary = 'get-analytics-summary',
  getWhatsAppChannel = 'get-whatsapp-channel',
  createWhatsappContent = 'create-whatsapp-content',
  editWhatsappContent = 'edit-whatsapp-content',
  saveWhatsappConfig = 'save-whatsapp-config',
  getWhatsappConfig = 'get-whatsapp-config',
  integrationsAPISecret = 'integrations-api-secret',
  createPushNotificationConfig = 'create-push-notification-config',
  getPushNotificationConfig = 'get-push-notification-config',
  getPushNotificationSender = 'get-push-notification-sender',
  getPushNotificationChannel = 'get-push-notification-channel',
  createPushNotificationChannel = 'create-push-notification-channel',
  editPushNotificationChannel = 'edit-push-notification-channel',
  exportChannelAnalytics = 'export-channel-analytics',
  fetchChannelAnalyticsDocument = 'fetch-channel-analytics-document',
  addCampaign = 'add-campaign',
  auditLog = 'audit-log',
  logout = 'logout',
  getPushNotificationConfigServiceAccount = 'get-push-notification-config-service-account',
  deletePushNotificationConfig = 'delete-push-notification-config',
  getSetupSDK = 'get-setup-sdk',
  getWhatsAppSenderList = 'get-whatsapp-sender-list',
  whatsappChannelDetail = 'whatsapp-channel-detail',
  getPushNotificationCategories = 'get-push-notification-categories',
  getEmailDomainList = 'get-email-domain-list',
  addNewDomain = 'add-new-domain',
  deleteEmailDomain = 'delete-email-domain',
  getDomainDetail = 'get-domain-detail',
  addNewEmailSender = 'add-new-email-sender',
  emailSenderList = 'email-sender-list',
  deleteEmailSender = 'delete-email-sender',
  inviteNewUser = 'invite-new-user',
  fetchUserList = 'fetch-user-list',
  deleteUser = 'delete-user',
  getUserAuth = 'get-user-auth',
  addNewContact = 'add-new-contact',
  deleteContact = 'delete-contact',
  fetchContactList = 'fetch-contact-list',
  getCategoryList = 'get-category-list',
  addNewCategory = 'add-new-category',
  deleteCategory = 'delete-category',
  createTaggingConfig = 'create-tagging-config',
  getTaggingList = 'get-tagging-list',
  deleteTaggingConfig = 'delete-tagging-config',
  editTaggingConfig = 'edit-tagging-config',
  getContactList = 'get-contact-list',
  getContactDetail = 'get-contact-detail',
  createBroadcast = 'create-broadcast',
  editBroadcast = 'edit-email-broadcast',
  createPushNotifBroadcast = 'create-push-notif-broadcast',
  editPushNotifBroadcast = 'edit-push-notif-broadcast',
  createWhatsappBroadcast = 'create-whatsapp-broadcast',
  editWhatsappBroadcast = 'edit-whatsapp-broadcast',
  getBroadcastList = 'get-broadcast-list',
  getWhatsappBroadcastList = 'get-whatsapp-broadcast-list',
  getPushNotifBroadcastList = 'get-push-notif-broadcast-list',
  deleteBroadcast = 'delete-broadcast',
  deletePushNotifBroadcast = 'delete-push-notif-broadcast',
  deleteWhatsappBroadcast = 'delete-whatsapp-broadcast',
  patchContact = 'patch-contact',
  getUnsubscribeConfig = 'get-unsubscribe-config',
  setUnsubscribeConfig = 'set-unsubscribe-config',
  getUnsubscribeList = 'get-unsubscribe-list',
  getUnsubscribeConfigGuest = 'get-unsubscribe-config-guest',
  submitUnsubscribe = 'submit-unsubscribe',
  getEmailDetail = 'get-email-detail',
  getPushNotifDetail = 'get-push-notif-detail',
  getWhatsappDetail = 'get-whatsapp-detail',
  patchUnsubscribePreference = 'patch-unsubscribe-preference',
  deleteUnsubscribe = 'delete-unsubscribe',
  getAnalyticsClickPerformance = 'get-analytics-click-performance',
  getTagList = 'get-tag-list',
  getTemplateReviewList = 'get-template-review-list',
  getTemplateReviewCount = 'get-template-review-count',
  setCheckerReviewAction = 'set-checker-review-action',
  getCheckerMakerTemplateDetail = 'get-checker-maker-template-detail',
  postComment = 'post-comment',
  getCommentList = 'get-comment-list',
  editComment = 'edit-comment',
  resolveComment = 'resolve-comment',
  deleteComment = 'delete-comment',
  sendChannelTemplateToChecker = 'send-channel-template-to-checker',
  getActivityList = 'get-activity-list',
  deleteCampaign = 'delete-campaign',
  deleteEmailChannel = 'delete-email-channel',
  deletePushNotifChannel = 'delete-push-notif-channel',
  deleteWhatsappChannel = 'delete-whatsapp-channel',
  getNotificationList = 'get-notification-list',
  readNotification = 'read-notification',
  getNotificationCount = 'get-notification-count',
  getSettings = 'get-settings',
  stopEmailChannel = 'stop-email-channel',
  stopPushNotifChannel = 'stop-push-notif-channel',
  stopWhatsappChannel = 'stop-whatsapp-channel',
  getChannelList = 'get-channel-list',
  putChannelLabel = 'put-channel-label',
  getAllContact = 'get-all-contact',
  getPushNotifBroadcastDetail = 'get-push-notif-broadcast-detail',
  getEmailBroadcastDetail = 'get-email-broadcast-detail',
  getWhatsappBroadcastDetail = 'get-whatsapp-broadcast-detail'
}
