import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { fetchAutomatedCampaignDetail } from '../../api';
import { useQuery } from 'react-query';
import { redirectTo404 } from '../../utils/error';
import { useNavigate } from 'react-router';

export const useGetAutomatedCampaignDetail = (id: string) => {
  const navigate = useNavigate();
  return useQuery({
    queryKey: [QUERY_KEYS_ENUM.automatedCampaignDetail, id],
    queryFn: () => fetchAutomatedCampaignDetail(id),
    enabled: !!id,
    select: ({ data }) => {
      return data;
    },
    onError: redirectTo404(navigate),
    retry: 1
  });
};
