import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';
import { createWhatsAppChannel } from '../../api/whatsapp';
import {
  CreateWhatsAppChannelPayloadInterface,
  ResponseAddChannelInterface
} from '../../shared/automated-campaign.interface';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';

export const useCreateWhatsappContent = ({
  onSuccess,
  onError
}: {
  onSuccess: (data: ResponseAddChannelInterface) => void;
  onError: (error: AxiosError) => void;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEYS_ENUM.createWhatsappContent],
    mutationFn: (payload: CreateWhatsAppChannelPayloadInterface) => {
      return createWhatsAppChannel(payload);
    },
    onSuccess: (data: ResponseAddChannelInterface) => {
      queryClient.invalidateQueries([QUERY_KEYS_ENUM.channelListAutomated]);
      onSuccess(data);
    },
    onError: (error: AxiosError) => {
      onError(error);
    }
  });
};
