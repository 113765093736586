import { NoticeType } from 'antd/lib/message/interface';
import { QUERY_KEYS_ENUM } from '../../static/query-keys.enum';
import { editAutomatedCampaign } from '../../api';
import { useMutation, useQueryClient } from 'react-query';
import { getErrorCode, getErrorMessage } from '../../utils/error';
import { AutomatedCampaignPayloadInterface } from '../../shared/automated-campaign.interface';
import { ErrorMessage } from '../../shared/errorMessage.enum';
import { HTTPErrorCode } from '../../shared/errorCode.enum';

export const handleEditAutomatedCampaignError =
  (toastMessage: (type: NoticeType, message: string) => void) => (error: any) => {
    const status = getErrorCode(error);
    switch (status) {
      case HTTPErrorCode.BAD_REQUEST:
        toastMessage('error', getErrorMessage(error));
        break;
      case HTTPErrorCode.CONFLICT:
        toastMessage('error', ErrorMessage.CampaignAlreadyExist);
        break;
      default:
        break;
    }
  };

export const useEditAutomatedCampaign = (
  id: string,
  toastMessage: (type: NoticeType, message: string) => void
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [QUERY_KEYS_ENUM.editAutomatedCampaign, id],
    mutationFn: (payload: AutomatedCampaignPayloadInterface) => {
      return editAutomatedCampaign(id, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(QUERY_KEYS_ENUM.automatedCampaignDetail);
    },
    onError: handleEditAutomatedCampaignError(toastMessage)
  });
};
