import { Token } from '@squantumengine/horizon';
import { CampaignTypeEnum } from '../../shared/automated-campaign.interface';

export enum CampaignStatusEnum {
  ACTIVE = 'activated',
  ARCHIVED = 'archived',
  DELETED = 'deleted'
}

export const generateStatusValue = (status?: string) => {
  switch (status) {
    case CampaignStatusEnum.ACTIVE:
      return {
        label: 'Aktif',
        color: Token.LABEL_TYPE.info
      };
    case CampaignStatusEnum.ARCHIVED:
      return {
        label: 'Diarsipkan',
        color: Token.LABEL_TYPE.default
      };
    default:
      return {
        label: 'Label',
        color: Token.LABEL_TYPE.default
      };
  }
};

export const getCampaignTypeText = (type?: CampaignTypeEnum) => {
  switch (type) {
    case CampaignTypeEnum.MANUAL:
      return 'Manual Campaign';
    case CampaignTypeEnum.AUTOMATED:
      return 'Automated Campaign';
    // TODO: delete default
    default:
      return 'Automated Campaign';
  }
};
