import { useParams } from 'react-router';
import _ from 'lodash';

function useCampaignParams() {
  const params = useParams();

  // is obsolete, added for temporary compatibility
  const campaignId = _.get(params, 'campaignId', '');
  // regular channelId (UUID)
  const channelId = _.get(params, 'channelId', '');
  // campaignType: manual or automated
  const campaignType = _.get(params, 'campaignType', '');
  // channelType: email, whatsapp, push notification, etc
  const channelType = _.get(params, 'channelType', '');

  return { campaignId, channelId, campaignType, channelType };
}

export default useCampaignParams;
